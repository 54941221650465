/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
export var messages = {
    checkGoogleAccount: {
        existsAccount: React.createElement(React.Fragment, null, "Google \uACC4\uC815(Gmail \uC8FC\uC18C)\uC774 \uC788\uC73C\uC2E0\uAC00\uC694?"),
        yes: '있다',
        no: '없다',
    },
    commentEnterFeedback: '의견과 감상 작성에 협조 부탁드립니다.',
    commentEnterFreeForm: '자유 기입',
    commentEnterInputGoodPoint: '지인에게 추천할 포인트나 만족하신 점을 적어주세요.',
    enquete: '고객 만족도 조사',
    enqueteNotFound: '설문지가 존재하지 않거나, 불러올 수 없습니다.',
    googleStep: {
        googleAccountPageNextStep: (React.createElement(React.Fragment, null, "Google \uACC4\uC815 \uD398\uC774\uC9C0\uC5D0 \uB4E4\uC5B4\uAC04 \uD6C4\uC758 \uB2E8\uACC4\uC785\uB2C8\uB2E4.")),
        goToGooglePageNow: React.createElement(React.Fragment, null, "\uC9C0\uAE08 \uBC14\uB85C Google \uD398\uC774\uC9C0\uB85C GO!"),
        step1: '“이 장소에서의 경험이나 감상을 공유합시다” 부분을 탭',
        step2: '입력란을 길게 눌러 “붙여넣기”가 표시되면 탭',
        step3: '텍스트가 붙여넣어졌으면 “완료”를 탭',
        step4: '★(별)을 탭',
        step5: '선택지가 있으면, 감상에 가장 가까운 태그를 선택하여 탭',
        step6: '화면 오른쪽 상단의 “게시”를 탭하면 완료됩니다',
    },
    loading: '불러오는 중...',
    multipleSelectAllowed: '복수 선택 가능',
    name: '이름',
    namePlaceholder: '예) 타나카 이치로',
    next: '다음',
    npsOption1: '전혀 추천하고 싶지 않다',
    npsOption2: '추천하고 싶지 않다',
    npsOption3: '추천하고 싶다',
    npsOption4: '매우 추천하고 싶다',
    npsText: '저희 가게를 지인에게 추천하고 싶으신가요?',
    other: '기타',
    otherPlaceholder: '기타 내용을 기입해주세요',
    plaeseAgree: '개인정보 처리방침에 동의합니다',
    pleaseAgreeWithUrl: function (privacyPolicyUrl) { return (React.createElement(React.Fragment, null,
        React.createElement("a", { className: "privacy-policy-text", href: privacyPolicyUrl, target: "_blank", rel: "noreferrer", style: { textDecoration: 'underline' } }, "\uAC1C\uC778\uC815\uBCF4 \uCC98\uB9AC\uBC29\uCE68"),
        "\uC5D0 \uB3D9\uC758\uD569\uB2C8\uB2E4")); },
    pleaseEnter: '입력해 주세요.',
    pleaseEnterTextLength: function (min, length) {
        return "\uCD5C\uC18C " + min + "\uC790 \uC785\uB825\uC744 \uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4: \uD604\uC7AC " + length + "\uC790";
    },
    pleaseSelect: '선택해 주세요.',
    pleaseSelectOneOrMore: '하나 이상 선택해 주세요.',
    alertForIOS: {
        qrReaderPattern: {
            title: (React.createElement(React.Fragment, null,
                "\uD654\uBA74 \uC624\uB978\uCABD \uD558\uB2E8\uC5D0 \uADF8\uB9BC\uACFC \uAC19\uC740",
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\uC544\uC774\uCF58\uC774",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\uC788\uB294")),
                "\uACBD\uC6B0")),
            description: '화면 오른쪽 하단의 아이콘을 탭하여 Safari 에서 설문지를 작성해 주세요.',
            caption: 'Safari 아이콘의 예',
            directOpenLink: function (onStartEnquete) { return (React.createElement(React.Fragment, null,
                "\uC704\uC758 \uBC84\uD2BC\uC73C\uB85C \uC5F4 \uC218 \uC5C6\uB294 \uACBD\uC6B0",
                React.createElement("a", { style: { color: 'blue', textDecoration: 'underline' }, onClick: onStartEnquete }, "\uC5EC\uAE30\uB97C \uD074\uB9AD\uD558\uC138\uC694"),
                ".")); },
        },
        safariPattern: {
            title: (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\uC544\uC774\uCF58\uC774",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\uC5C6\uB294")),
                "\uACBD\uC6B0")),
            description: '아래 버튼을 탭해 주세요.',
            enqueteStartButton: '설문 시작하기',
        },
    },
    required: '필수',
    secondStep: {
        followOfficialSnsForDeals: (React.createElement(React.Fragment, null, "\uACF5\uC2DD SNS\uC5D0\uC11C\uB294 \uC720\uC775\uD55C \uC815\uBCF4\uB3C4 \uC81C\uACF5\uD558\uACE0 \uC788\uC73C\uB2C8 \uD314\uB85C\uC6B0 \uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4.")),
        googleReviewRequestMessage: (React.createElement(React.Fragment, null, "\uAC00\uB2A5\uD558\uC2DC\uB2E4\uBA74 Google \uB9AC\uBDF0 \uC791\uC131\uC5D0\uB3C4 \uD611\uC870 \uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4!")),
        responseDateTime: '응답 일시',
        responseId: '응답 ID',
        surveyThanksMessage: React.createElement(React.Fragment, null, "\uC124\uBB38\uC5D0 \uC751\uD574 \uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4."),
        useForFutureOperation: '향후 운영에 활용하겠습니다.',
    },
    toGoogle: {
        copyToGoogle: '복사하여 Google로',
        freeCommentCopyOnButton: '버튼을 누르면, “자유 댓글란”의 내용이 복사됩니다',
        missingPlaceIdOrShortName: '약칭 또는 Place ID가 설정되어 있지 않습니다.',
        moveToGoogleAndPasteReview: '버튼을 누르면 Google 계정 페이지로 이동합니다. 리뷰란에 내용을 붙여넣어 사용하세요.',
    },
    textValidationError: {
        number: '숫자로 입력해 주세요',
        alphanumeric: '반각 영숫자로 입력해 주세요',
        email: '이메일 형식으로 입력해 주세요',
    },
};
