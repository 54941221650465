var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { MessageContext } from '../context/message-context';
import HaveAccounut from './CheckGoogleAccount/img/haveAccount.svg';
import NotHaveAccounut from './CheckGoogleAccount/img/notHaveAccount.svg';
export default function CheckGoogleAccount(props) {
    var enqueteForm = props.enqueteForm, enqueteSetting = props.enqueteSetting, setEnqueteForm = props.setEnqueteForm;
    var msg = useContext(MessageContext).msg;
    var handleClickButton = function (hasGoogleAccount) {
        if (hasGoogleAccount) {
            window.location.hash = 'gmb';
        }
        else {
            if (enqueteSetting.snsLink !== undefined) {
                window.location.hash = 'sns';
            }
            else {
                window.location.hash = 'thanks';
            }
        }
        setEnqueteForm(__assign(__assign({}, enqueteForm), { hasGoogleAccount: hasGoogleAccount, step: 3 }));
    };
    return (React.createElement("div", { style: { marginTop: '64px' } },
        React.createElement("h1", { style: { fontWeight: 'bold', marginBottom: '32px' } }, msg.checkGoogleAccount.existsAccount),
        React.createElement("div", null,
            React.createElement("div", { style: { display: 'block', margin: '16px auto' } },
                React.createElement("img", { src: HaveAccounut, alt: "", style: { display: 'block', margin: '0 auto' } }),
                React.createElement("button", { onClick: function () { return handleClickButton(true); }, style: {
                        display: 'block',
                        width: '275px',
                        margin: '16px auto',
                        padding: '16px',
                        borderRadius: '56px',
                    } }, msg.checkGoogleAccount.yes)),
            React.createElement("div", { style: { display: 'block', margin: '56px auto' } },
                React.createElement("img", { src: NotHaveAccounut, alt: "", style: { display: 'block', margin: '0 auto' } }),
                React.createElement("button", { onClick: function () { return handleClickButton(false); }, style: {
                        display: 'block',
                        width: '275px',
                        margin: '16px auto',
                        padding: '16px',
                        borderRadius: '56px',
                        backgroundColor: '#505050',
                    } }, msg.checkGoogleAccount.no)))));
}
