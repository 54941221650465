import React, { useContext, useEffect } from 'react';
import { MessageContext } from '../context/message-context';
export function StartPageForIOSSafari(props) {
    var themeColor = props.themeColor, onStartEnquete = props.onStartEnquete, showChromeRecommend = props.showChromeRecommend;
    var isFromQRCode = window.location.hash === '#from-qr';
    useEffect(function () {
        // QRCodeから一度表示されたらloadedにしておき、Safariへ遷移した際にはSafariを開くボタンを表示しないようにする
        window.location.hash = '#loaded';
    }, []);
    var handleOpenButtonClicked = function () {
        if (showChromeRecommend) {
            location.href = "googlechrome://" + location.hostname + location.pathname + location.search + location.hash;
        }
        else {
            // Chrome を使わず、そのままアンケートを開く
            onStartEnquete();
        }
    };
    if (isFromQRCode) {
        return (React.createElement(SafariReopenSuggestion, { themeColor: themeColor, handleOpenButtonClicked: handleOpenButtonClicked, onStartEnquete: onStartEnquete, showChromeRecommend: showChromeRecommend }));
    }
    return (React.createElement(OpenInChromeOrSafari, { themeColor: themeColor, handleOpenButtonClicked: handleOpenButtonClicked, onStartEnquete: onStartEnquete, showChromeRecommend: showChromeRecommend }));
}
var Content = function (_a) {
    var children = _a.children, themeColor = _a.themeColor;
    return (React.createElement("div", { style: {
            border: "5px solid " + themeColor,
            borderRadius: '3px',
            backgroundColor: 'white',
            padding: '20px 15px',
            width: '90vw',
            maxWidth: '480px',
        } }, children));
};
/*
 * QRコードから開いた場合に、Safariで開き直すように促すためのコンポーネント
 */
var SafariReopenSuggestion = function (_a) {
    var themeColor = _a.themeColor, handleOpenButtonClicked = _a.handleOpenButtonClicked, onStartEnquete = _a.onStartEnquete, showChromeRecommend = _a.showChromeRecommend;
    var msg = useContext(MessageContext).msg;
    return (React.createElement("div", { style: {
            flexGrow: 1,
            alignContent: 'center',
        } },
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                color: '#777',
                fontSize: '1.5rem',
            } },
            React.createElement(Content, { themeColor: themeColor },
                React.createElement("p", { style: { fontWeight: 'bold' } }, msg.alertForIOS.qrReaderPattern.title),
                React.createElement("p", { style: {
                        lineHeight: '2.4rem',
                        marginBottom: '20px',
                    } }, msg.alertForIOS.qrReaderPattern.description),
                React.createElement("div", { style: {
                        display: 'block',
                        margin: '0px auto',
                        border: '5px solid #ffcc58',
                        borderRadius: '4px',
                        textAlign: 'center',
                        width: 'fit-content',
                        padding: '10px 50px',
                    } },
                    React.createElement("p", { style: {
                            fontWeight: 'bold',
                            marginBottom: '10px',
                        } }, msg.alertForIOS.qrReaderPattern.caption),
                    React.createElement("img", { src: "/img/icon_safari_tap.svg", alt: "" }))),
            React.createElement(Content, { themeColor: themeColor },
                React.createElement("p", { style: { fontWeight: 'bold' } }, msg.alertForIOS.safariPattern.title),
                React.createElement("p", { style: {
                        lineHeight: '2.4rem',
                        marginBottom: '20px',
                    } }, msg.alertForIOS.safariPattern.description),
                React.createElement("button", { style: {
                        display: 'block',
                        margin: '0px auto',
                        padding: '20px 20px',
                        borderRadius: '15px',
                        fontWeight: 'bold',
                    }, onClick: handleOpenButtonClicked },
                    msg.alertForIOS.safariPattern.enqueteStartButton,
                    React.createElement("img", { src: "img/arrow.svg", style: { marginLeft: '10px' }, alt: "" })),
                showChromeRecommend && (React.createElement("p", { style: {
                        marginTop: '10px',
                    } }, msg.alertForIOS.qrReaderPattern.directOpenLink(onStartEnquete)))))));
};
/*
 * Safariで開いた場合に、Chromeで開き直してもらうためのコンポーネント
 */
var OpenInChromeOrSafari = function (_a) {
    var themeColor = _a.themeColor, handleOpenButtonClicked = _a.handleOpenButtonClicked, onStartEnquete = _a.onStartEnquete, showChromeRecommend = _a.showChromeRecommend;
    var msg = useContext(MessageContext).msg;
    return (React.createElement("div", { style: {
            flexGrow: 1,
            alignContent: 'center',
            color: '#777',
            fontSize: '1.5rem',
        } },
        React.createElement(Content, { themeColor: themeColor },
            React.createElement("p", { style: {
                    lineHeight: '2.4rem',
                    marginBottom: '20px',
                } }, msg.alertForIOS.safariPattern.description),
            React.createElement("button", { style: {
                    display: 'block',
                    margin: '0px auto',
                    padding: '20px 20px',
                    borderRadius: '15px',
                    fontWeight: 'bold',
                }, onClick: handleOpenButtonClicked },
                msg.alertForIOS.safariPattern.enqueteStartButton,
                React.createElement("img", { src: "img/arrow.svg", style: { marginLeft: '10px' }, alt: "" })),
            showChromeRecommend && (React.createElement("p", { style: {
                    marginTop: '10px',
                } }, msg.alertForIOS.qrReaderPattern.directOpenLink(onStartEnquete))))));
};
