import axios from 'axios';
import React from 'react';
import { API_END_POINT } from '../../../api/api';
import { sendData } from '../../../utils/GoogleAnalyticsUtil';
import { isValidHttpUrl } from '../../../utils/url';
import FacebookIcon from './img/facebook_icon.svg';
import InstagramIcon from './img/instagram_icon.svg';
import LineIcon from './img/line_icon.svg';
import PinterestIcon from './img/pinterest_icon.svg';
import TiktokIcon from './img/tiktok_icon.svg';
import TwitterIcon from './img/twitter_icon.svg';
import YoutubeIcon from './img/youtube_icon.png';
export default function SnsFollow(props) {
    var enquete = props.enquete, enqueteForm = props.enqueteForm, enqueteSetting = props.enqueteSetting;
    var instagram = function () {
        var _a;
        if ((_a = enqueteSetting.snsLink) === null || _a === void 0 ? void 0 : _a.instagramLink) {
            return (React.createElement("a", { href: isValidHttpUrl(enqueteSetting.snsLink.instagramLink)
                    ? enqueteSetting.snsLink.instagramLink
                    : "https://instagram.com/" + enqueteSetting.snsLink.instagramLink, className: "sns-button instagram-button", onClick: function () { return goSns('instagram'); }, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: InstagramIcon })));
        }
    };
    var line = function () {
        var _a;
        if ((_a = enqueteSetting.snsLink) === null || _a === void 0 ? void 0 : _a.lineLink) {
            return (React.createElement("a", { href: isValidHttpUrl(enqueteSetting.snsLink.lineLink)
                    ? enqueteSetting.snsLink.lineLink
                    : "https://line.me/R/ti/p/" + enqueteSetting.snsLink.lineLink, className: "sns-button line-button", onClick: function () { return goSns('line'); }, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: LineIcon })));
        }
    };
    var facebook = function () {
        var _a, _b;
        if ((_a = enqueteSetting.snsLink) === null || _a === void 0 ? void 0 : _a.facebookLink) {
            return (React.createElement("a", { href: isValidHttpUrl(enqueteSetting.snsLink.facebookLink)
                    ? enqueteSetting.snsLink.facebookLink
                    : "https://www.facebook.com/" + (((_b = enqueteSetting.snsLink) === null || _b === void 0 ? void 0 : _b.facebookLink.match(/^[0-9]+$/))
                        ? 'profile.php?id='
                        : '') + enqueteSetting.snsLink.facebookLink, className: "sns-button facebook-button", onClick: function () { return goSns('facebook'); }, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: FacebookIcon })));
        }
    };
    var twitter = function () {
        var _a;
        if ((_a = enqueteSetting.snsLink) === null || _a === void 0 ? void 0 : _a.twitterLink) {
            return (React.createElement("a", { href: isValidHttpUrl(enqueteSetting.snsLink.twitterLink)
                    ? enqueteSetting.snsLink.twitterLink
                    : "https://twitter.com/" + enqueteSetting.snsLink.twitterLink, className: "sns-button twitter-button", onClick: function () { return goSns('twitter'); }, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: TwitterIcon })));
        }
    };
    var youtube = function () {
        var _a;
        if ((_a = enqueteSetting.snsLink) === null || _a === void 0 ? void 0 : _a.youtubeLink) {
            return (React.createElement("a", { href: isValidHttpUrl(enqueteSetting.snsLink.youtubeLink)
                    ? enqueteSetting.snsLink.youtubeLink
                    : "https://youtube.com/@" + enqueteSetting.snsLink.youtubeLink, className: "sns-button youtube-button", onClick: function () { return goSns('youtube'); }, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: YoutubeIcon })));
        }
    };
    var tiktok = function () {
        var _a;
        if ((_a = enqueteSetting.snsLink) === null || _a === void 0 ? void 0 : _a.tiktokLink) {
            return (React.createElement("a", { href: isValidHttpUrl(enqueteSetting.snsLink.tiktokLink)
                    ? enqueteSetting.snsLink.tiktokLink
                    : "https://tiktok.com/@" + enqueteSetting.snsLink.tiktokLink, className: "sns-button tiktok-button", onClick: function () { return goSns('tiktok'); }, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: TiktokIcon })));
        }
    };
    var pinterest = function () {
        var _a;
        if ((_a = enqueteSetting.snsLink) === null || _a === void 0 ? void 0 : _a.pinterestLink) {
            return (React.createElement("a", { href: isValidHttpUrl(enqueteSetting.snsLink.pinterestLink)
                    ? enqueteSetting.snsLink.pinterestLink
                    : "https://www.pinterest.com/" + enqueteSetting.snsLink.pinterestLink, className: "sns-button pinterest-button", onClick: function () { return goSns('pinterest'); }, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: PinterestIcon })));
        }
    };
    var goSns = function (sns) {
        if (enqueteForm.responseId) {
            axios.post(API_END_POINT + "/public/enquetes/" + enquete.uid + "/responses/" + enqueteForm.responseId + "/tracking", { value: "clicked_" + sns });
        }
        sendData("click link\u300C" + sns + "\u300D", 'Click SNS Button');
    };
    return (React.createElement("div", null,
        React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', padding: '0 32px' } },
            instagram(),
            line(),
            facebook(),
            twitter(),
            youtube(),
            tiktok(),
            pinterest())));
}
