// idで検索できるように埋め込むscript用の名前を定義
var SCRIPT1_NAME = 'gtag';
var SCRIPT2_NAME = 'optimize';
var SCRIPT3_NAME = 'gtagScript';
/** gtag.js読み込み用関数 */
export var initializeGA = function (googleAnalyticsId__GA, googleAnalyticsId__UA, optimizeId) {
    // トラッキングID or 測定IDが空の場合は終了
    if (googleAnalyticsId__GA === '')
        return;
    // gtag.jsをheadタグに埋め込み
    var script1 = document.createElement('script');
    script1.id = SCRIPT1_NAME;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsId__GA;
    script1.async = true;
    document.head.appendChild(script1);
    // optimize.jsをheadタグに埋め込み
    var script2 = document.createElement('script');
    script2.id = SCRIPT2_NAME;
    script2.src = "https://www.googleoptimize.com/optimize.js?id=" + optimizeId;
    script2.async = true;
    document.head.appendChild(script2);
    // 実行用scriptをheadタグに埋め込み
    var script3 = document.createElement('script');
    script3.id = SCRIPT3_NAME;
    script3.text = "window.dataLayer = window.dataLayer || [];\n  function gtag() { dataLayer.push(arguments); }\n  gtag('js', new Date());\n  gtag('config', '" + googleAnalyticsId__GA + "');\n  gtag('config', '" + googleAnalyticsId__UA + "', {'optimize_id':'" + optimizeId + "'});";
    document.head.appendChild(script3);
};
export var sendData = function (eventName, eventCategory, eventLabel, value) {
    if (eventLabel === void 0) { eventLabel = ''; }
    if (value === void 0) { value = 0; }
    if (!window.gtag)
        return;
    window.gtag('event', eventName, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: value,
    });
};
export var testStart = function () {
    var script = document.createElement('script');
    script.text = "dataLayer.push({'event': 'test.start'});";
    document.head.appendChild(script);
};
