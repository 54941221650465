/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
export var messages = {
    checkGoogleAccount: {
        existsAccount: (React.createElement(React.Fragment, null,
            "Do you have",
            React.createElement("br", null),
            "a Google Account",
            React.createElement("br", null),
            "(Gmail address)?")),
        yes: 'Yes',
        no: 'No',
    },
    commentEnterFeedback: 'Please provide your feedback and opinions.',
    commentEnterFreeForm: 'Free form',
    commentEnterInputGoodPoint: 'Please share the points that you would recommend to your acquaintances or the points that you were satisfied with.',
    enquete: 'Customer Satisfaction Survey',
    enqueteNotFound: 'The survey does not exist or could not be loaded.',
    googleStep: {
        googleAccountPageNextStep: (React.createElement(React.Fragment, null, "The following steps are explained after you have proceeded to the Google Account page.")),
        goToGooglePageNow: (React.createElement(React.Fragment, null,
            "Let",
            "'",
            "s go",
            React.createElement("br", null),
            "to the Google page now!")),
        step1: 'Tap the "Share your experience and thoughts about this place" section',
        step2: 'Long press the input field and tap "Paste" when it appears',
        step3: 'Tap "Done" when the text is pasted',
        step4: 'Tap the ★ (star)',
        step5: 'If there are options, select the tag that is closest to your opinion and tap it',
        step6: 'Tap "Submit" at the top right of the screen to complete',
    },
    loading: 'Loading...',
    multipleSelectAllowed: 'Multiple selections allowed',
    name: 'Name',
    namePlaceholder: 'e.g. John Smith',
    next: 'Next',
    npsOption1: "Really don't want to recommend",
    npsOption2: "Don't want to recommend",
    npsOption3: 'Want to recommend',
    npsOption4: 'Really want to recommend',
    npsText: 'Would you recommend our store to your friends or acquaintances?',
    other: 'Other',
    otherPlaceholder: 'Please enter',
    plaeseAgree: 'Agree to the privacy policy',
    pleaseAgreeWithUrl: function (privacyPolicyUrl) { return (React.createElement(React.Fragment, null,
        "Agree to the",
        React.createElement("a", { className: "privacy-policy-text", href: privacyPolicyUrl, target: "_blank", rel: "noreferrer", style: { textDecoration: 'underline' } }, "privacy policy"))); },
    pleaseEnter: 'Please enter.',
    pleaseEnterTextLength: function (min, length) {
        return "Please enter at least " + min + " characters. Currently " + length + " characters have been entered.";
    },
    pleaseSelect: 'Please select.',
    pleaseSelectOneOrMore: 'Please select one or more.',
    alertForIOS: {
        qrReaderPattern: {
            title: (React.createElement(React.Fragment, null,
                "If",
                ' ',
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "there",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, " is "),
                    "an icon"),
                ' ',
                "as shown in the bottom right of the screen")),
            description: 'Please continue in Safari by tapping the icon at the bottom right of the screen.',
            caption: 'Safari icon example',
            directOpenLink: function (onStartEnquete) { return (React.createElement(React.Fragment, null,
                "If you cannot open with the button above, please",
                ' ',
                React.createElement("a", { style: { color: 'blue', textDecoration: 'underline' }, onClick: onStartEnquete }, "tap here"),
                ".")); },
        },
        safariPattern: {
            title: (React.createElement(React.Fragment, null,
                "If",
                ' ',
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "there is",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, " no "),
                    " icon"))),
            description: 'Please tap the button below.',
            enqueteStartButton: 'Start answering surveys',
        },
    },
    required: 'Required',
    secondStep: {
        followOfficialSnsForDeals: (React.createElement(React.Fragment, null, "We also offer special deals and promotions on our official SNS. Please follow us.")),
        googleReviewRequestMessage: (React.createElement(React.Fragment, null, "If you have a moment, we would appreciate it if you could also leave a review on Google.")),
        responseDateTime: 'Response Date and Time',
        responseId: 'Response ID',
        surveyThanksMessage: (React.createElement(React.Fragment, null, "Thank you for your time in completing this survey.")),
        useForFutureOperation: 'We will use your feedback to improve our future operations.',
    },
    toGoogle: {
        copyToGoogle: 'Copy and go to Google',
        freeCommentCopyOnButton: 'By pressing the button, the contents of the "Free Comment Field" will be copied.',
        missingPlaceIdOrShortName: 'Short name or Place ID is not set.',
        moveToGoogleAndPasteReview: 'When you press the button, you will be forwarded to your Google Account page. Please use the copied content to paste it into the Review field.',
    },
    textValidationError: {
        number: 'Please enter a number.',
        alphanumeric: 'Please enter in half-width alphanumeric characters.',
        email: 'Please enter in email address format.',
    },
};
