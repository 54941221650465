import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useContext } from 'react';
import { MessageContext } from '../../../context/message-context';
import { replacePlaceholder } from '../../../utils/EnqueteHelper';
import { lineBreakToParagraphAndHtml } from '../../../utils/browserUtils';
import GoogleInductionIcon from './img/google_induction_icon.svg';
import PiggIcon from './img/pigg.svg';
dayjs.extend(utc);
dayjs.extend(timezone);
export default function Title(props) {
    var enqueteSetting = props.enqueteSetting, enqueteForm = props.enqueteForm, titleType = props.titleType, hideTitleImage = props.hideTitleImage;
    var msg = useContext(MessageContext).msg;
    var Timestamp = function () {
        return (React.createElement("p", { style: {
                marginTop: '5px',
                textAlign: 'center',
                fontSize: '15px',
                color: '#777',
            } },
            msg.secondStep.responseDateTime,
            ":",
            ' ',
            enqueteForm.createdAt &&
                dayjs
                    .tz(enqueteForm.createdAt, 'UTC')
                    .tz('Asia/Tokyo')
                    .format('YYYY-MM-DD HH:mm:ss'),
            ", ",
            msg.secondStep.responseId,
            ": ",
            enqueteForm.responseId));
    };
    var CustomTitle = function () {
        return (React.createElement("div", { className: "section-title" },
            React.createElement("div", { style: { margin: '0 auto 8px' } },
                React.createElement(Timestamp, null)),
            React.createElement("div", { style: { margin: '0 auto 24px' } }, lineBreakToParagraphAndHtml(replacePlaceholder(enqueteForm, enqueteSetting.completedText) || ''))));
    };
    var DefaultTitle = function () {
        return (React.createElement("div", { className: "section-title" },
            React.createElement("h2", null, msg.secondStep.surveyThanksMessage),
            React.createElement(Timestamp, null),
            React.createElement("div", { className: "step-support" },
                React.createElement("div", { className: "step-support--icon" },
                    React.createElement("img", { src: GoogleInductionIcon, alt: "" })),
                React.createElement("div", { className: "step-support--text" },
                    React.createElement("p", null, msg.secondStep.googleReviewRequestMessage)))));
    };
    var ThanksTitleCustom = function () {
        return (React.createElement("div", { style: { marginTop: '24px' } },
            React.createElement("h2", { style: {
                    fontSize: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    lineHeight: '120%',
                } }, msg.secondStep.surveyThanksMessage),
            React.createElement(Timestamp, null),
            React.createElement("div", { style: { fontSize: '16px', textAlign: 'center', marginTop: '28px' } }, lineBreakToParagraphAndHtml(replacePlaceholder(enqueteForm, enqueteSetting.completedThanksText) || '')),
            React.createElement("div", { className: "piggIcon" },
                React.createElement("img", { src: PiggIcon, alt: "" }))));
    };
    var ThanksTitleDefault = function () {
        return (React.createElement("div", { style: { marginTop: '24px' } },
            React.createElement("h2", { style: {
                    fontSize: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    lineHeight: '120%',
                } }, msg.secondStep.surveyThanksMessage),
            React.createElement(Timestamp, null),
            React.createElement("p", { style: { fontSize: '16px', textAlign: 'center', marginTop: '28px' } }, msg.secondStep.useForFutureOperation),
            !hideTitleImage && (React.createElement("div", { className: "piggIcon" },
                React.createElement("img", { src: PiggIcon, alt: "" })))));
    };
    var SnsLinkTitle = function () {
        return (React.createElement("div", { className: "section-title" },
            React.createElement("h2", null, msg.secondStep.surveyThanksMessage),
            React.createElement(Timestamp, null),
            React.createElement("div", { className: "step-support" },
                React.createElement("div", { className: "step-support--icon" },
                    React.createElement("img", { src: GoogleInductionIcon, alt: "" })),
                React.createElement("div", { className: "step-support--text" },
                    React.createElement("p", { style: { fontWeight: 'bold', maxWidth: '334px' } }, enqueteSetting.officialSnsFollowRequestText
                        ? lineBreakToParagraphAndHtml(replacePlaceholder(enqueteForm, enqueteSetting.officialSnsFollowRequestText) || '')
                        : msg.secondStep.followOfficialSnsForDeals)))));
    };
    var GoogleTitle = enqueteSetting.completedText ? CustomTitle : DefaultTitle;
    var ThanksTitle = enqueteSetting.completedThanksText != null
        ? ThanksTitleCustom
        : ThanksTitleDefault;
    var Content = function () {
        switch (titleType) {
            case 'google':
                return React.createElement(GoogleTitle, null);
            case 'snsLink':
                return React.createElement(SnsLinkTitle, null);
            case 'thanks':
                return React.createElement(ThanksTitle, null);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Content, null)));
}
