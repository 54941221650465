var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as Bowser from 'bowser';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import useEnquete from '../api/use-enquete';
import CheckGoogleAccount from '../components/CheckGoogleAccount';
import FirstStep from '../components/FirstStep';
import { Loading } from '../components/Loading';
import SecondStepGmb from '../components/SecondStepGmb';
import { StartPageForIOSSafari } from '../components/StartPageForIOSSafari';
import { MessageContext } from '../context/message-context';
import useEnqueteDesign2 from '../hooks/useEnqueteDesign2';
import Messages from '../i18n';
import { sendData } from '../utils/GoogleAnalyticsUtil';
var styles = {
    pageNotFoundWrapper: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',\n      'Hiragino Sans', Meiryo, sans-serif;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: calc(-110px + 100vh);\n    line-height: 1.5;\n    color: black;\n  "], ["\n    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',\n      'Hiragino Sans', Meiryo, sans-serif;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: calc(-110px + 100vh);\n    line-height: 1.5;\n    color: black;\n  "]))),
    pageNotFoundTitle: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 64px;\n    font-weight: bold;\n    text-align: center;\n    margin: 2rem 0px;\n  "], ["\n    font-size: 64px;\n    font-weight: bold;\n    text-align: center;\n    margin: 2rem 0px;\n  "]))),
    pageNotFoundDescription: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 20px;\n    text-align: center;\n  "], ["\n    font-size: 20px;\n    text-align: center;\n  "]))),
    selectStyle: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 10px;\n    border: 2px solid #ccc;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n    font-size: 16px;\n    color: #333;\n    &:focus {\n      border-color: #007bff;\n      outline: none;\n    }\n  "], ["\n    padding: 10px;\n    border: 2px solid #ccc;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n    font-size: 16px;\n    color: #333;\n    &:focus {\n      border-color: #007bff;\n      outline: none;\n    }\n  "]))),
    optionStyle: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 10px;\n    font-size: 16px;\n  "], ["\n    padding: 10px;\n    font-size: 16px;\n  "]))),
};
var defaultEnqueteForm = {
    step: 1,
    name: '',
    nps: undefined,
    comment: '',
    fieldValues: [],
    responseId: undefined,
    debug: false,
    hasGoogleAccount: true,
};
var langParamToEnqueteLanguage = function (langParam) {
    switch (langParam) {
        case 'ja':
        case 'en':
        case 'zh-cn':
        case 'zh-tw':
        case 'ko':
            return langParam;
        default:
            return 'ja';
    }
};
export default function EnquetePage() {
    var _this = this;
    var searchParams = new URL(window.location.href).searchParams;
    var enqueteUid = window.location.pathname.substring(1);
    var _a = useState(defaultEnqueteForm), enqueteForm = _a[0], setEnqueteForm = _a[1];
    var step = enqueteForm.step;
    var langParam = searchParams.get('lang');
    var _b = useState(langParamToEnqueteLanguage(langParam)), lang = _b[0], setLang = _b[1];
    var _c = useEnquete(enqueteUid), enquete = _c.enquete, reload = _c.reload, updateEnquete = _c.updateEnquete, workspace = _c.workspace, isLoadingEnquete = _c.isLoadingEnquete;
    var progressNewEnqueteDesign = useEnqueteDesign2(enquete).progressNewEnqueteDesign;
    var _d = useState(false), visibleStartPageForIOSSafari = _d[0], setVisibleStartPageForIOSSafari = _d[1];
    var _e = useState(false), isFormOpenedOnce = _e[0], setIsFormOpenedOnce = _e[1];
    var showPreviewAlert = searchParams.get('_src') === 'プレビュー';
    var isEditorPreview = searchParams.get('mode') === 'preview' && !showPreviewAlert;
    var msg = useMemo(function () {
        return Messages.get(lang);
    }, [lang]);
    // 初回のアンケート取得を行う effect
    useEffect(function () {
        void reload(lang);
    }, []);
    useEffect(function () {
        var modeParam = searchParams.get('mode');
        if (modeParam === 'preview') {
            setEnqueteForm(__assign(__assign({}, enqueteForm), { debug: true }));
        }
        window.addEventListener('message', function (e) {
            if (e.origin === process.env.REACT_APP_PREVIEW_MSG_SENDER_ORIGIN) {
                var setting = e.data.setting;
                // プレビューで項目の変更を表示するための処理
                if (setting) {
                    if (!(enquete === null || enquete === void 0 ? void 0 : enquete.json)) {
                        updateEnquete(__assign(__assign({}, enquete), { json: JSON.stringify(setting) }));
                    }
                    else {
                        var basedSetting = JSON.parse(enquete.json);
                        updateEnquete(__assign(__assign({}, enquete), { json: JSON.stringify(__assign(__assign({}, basedSetting), setting)) }));
                    }
                }
            }
        });
    }, []);
    useEffect(function () {
        if (enquete) {
            // FUJISAN-157 iOSのQRコードリーダーでページを読み込んだ際に、Safariで開くようにナビゲートする
            var env = Bowser.parse(window.navigator.userAgent);
            var isiOSSafari = (env.os.name === 'iOS' ||
                env.platform.model === 'iPad' ||
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) &&
                !navigator.userAgent.includes('CriOS');
            var showStartPageForIOSSafari = window.location.hash === '#from-qr' ||
                window.location.hash === '#loaded';
            if (isiOSSafari && showStartPageForIOSSafari) {
                // 初回読み込み
                setVisibleStartPageForIOSSafari(true);
            }
            // パラメータ
            var debugParam = searchParams.get('debug');
            if (debugParam) {
                var stepParam = searchParams.get('step');
                var step_1 = stepParam ? parseInt(stepParam) : enqueteForm.step;
                var npsParam = searchParams.get('nps');
                var nps = npsParam ? parseInt(npsParam) : enqueteForm.nps;
                setEnqueteForm(__assign(__assign({}, enqueteForm), { debug: true, step: step_1, nps: nps }));
            }
            if (window.location.hash.match('step2')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { step: 2 }));
            }
            else if (window.location.hash.match('gmb')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { hasGoogleAccount: true, step: 3 }));
            }
            else if (window.location.hash.match('sns')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { hasGoogleAccount: false, step: 3 }));
            }
            else if (window.location.hash.match('thanks')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { hasGoogleAccount: false, step: 3 }));
            }
            var modeParam = searchParams.get('mode');
            if (modeParam !== 'preview') {
                sendData('start Enquete', 'アンケート開始');
            }
        }
    }, [enquete]);
    // リダイレクト用 useEffect
    useEffect(function () {
        if (enquete) {
            var setting = JSON.parse(enquete.json);
            var redirectEnabled = setting.redirectEnabled;
            var redirectUrl = setting.redirectUrl;
            // 編集画面のプレビューではリダイレクトしない
            // プレビュー画面のプレビューではリダイレクトする
            if (redirectEnabled && redirectUrl && !isEditorPreview) {
                window.location.href = redirectUrl;
            }
        }
    }, [enquete]);
    var enqueteSetting = useMemo(function () {
        if (!(enquete === null || enquete === void 0 ? void 0 : enquete.json)) {
            return undefined;
        }
        var setting = JSON.parse(enquete.json);
        if (setting.lang) {
            setLang(setting.lang);
        }
        return setting;
    }, [enquete]);
    var head = function () {
        return (_jsxs(Helmet, __assign({ defer: false }, { children: [_jsx("title", { children: (enqueteSetting === null || enqueteSetting === void 0 ? void 0 : enqueteSetting.header) || msg.enquete }, void 0),
                process.env.REACT_APP_STAGE === 'stg' && (_jsx("meta", { name: "robots", content: "noindex, nofollow" }, void 0))] }), void 0));
    };
    var previewAlert = function () {
        return (_jsx("div", __assign({ style: {
                fontSize: '16px',
                textAlign: 'center',
                width: 'calc(100% + 40px)',
                padding: '8px',
                marginLeft: '-20px',
                marginBottom: '20px',
                backgroundColor: '#ffb74e',
            } }, { children: "\u3053\u308C\u306F\u30A2\u30F3\u30B1\u30FC\u30C8\u306E\u30D7\u30EC\u30D3\u30E5\u30FC\u8868\u793A\u3067\u3059\u3002\u30A2\u30F3\u30B1\u30FC\u30C8\u306E\u8868\u793A\u56DE\u6570\u306B\u306F\u30AB\u30A6\u30F3\u30C8\u3055\u308C\u307E\u305B\u3093\u3002\u516C\u958B\u7528\u306EURL\u306F\u7BA1\u7406\u753B\u9762\u306E\u300CURL\u3092\u30B3\u30D4\u30FC\u3059\u308B\u300D\u304B\u3089\u53D6\u5F97\u3057\u3066\u304F\u3060\u3055\u3044\u3002" }), void 0));
    };
    var languageSelector = function () {
        var searchParams = new URL(window.location.href).searchParams;
        var modeParam = searchParams.get('mode');
        var isInlinePreview = modeParam === 'preview' && !showPreviewAlert;
        return (_jsx("div", __assign({ css: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n          margin-bottom: 1em;\n          display: flex;\n          justify-content: flex-end;\n        "], ["\n          margin-bottom: 1em;\n          display: flex;\n          justify-content: flex-end;\n        "]))) }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ css: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n              display: flex;\n              align-items: center;\n              gap: 8px;\n            "], ["\n              display: flex;\n              align-items: center;\n              gap: 8px;\n            "]))) }, { children: [_jsx("img", { css: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n                width: 20px;\n              "], ["\n                width: 20px;\n              "]))), src: "/img/globe.svg", alt: "\u8A00\u8A9E" }, void 0),
                            _jsxs("select", __assign({ css: styles.selectStyle, value: lang, onChange: function (e) { return __awaiter(_this, void 0, void 0, function () {
                                    var selectedLang, response, e_1, url;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (isInlinePreview) {
                                                    alert('プレビュー時はこの操作は実行できません。');
                                                    return [2 /*return*/];
                                                }
                                                // #loaded の場合に言語切り替えした場合は再度 from-qr にする
                                                if (window.location.hash === '#loaded') {
                                                    window.location.hash = '#from-qr';
                                                }
                                                selectedLang = e.target
                                                    .value;
                                                // 読込中は選択後の言語で表示したいのでここでセットし、もし翻訳に失敗した場合はもとに戻す
                                                setLang(selectedLang);
                                                _a.label = 1;
                                            case 1:
                                                _a.trys.push([1, 3, , 4]);
                                                return [4 /*yield*/, reload(selectedLang)];
                                            case 2:
                                                response = _a.sent();
                                                if (response && response.hasTranslationError) {
                                                    // OpenAI API で何らかのエラーが起きた場合
                                                    throw new Error('Failed to translate.');
                                                }
                                                return [3 /*break*/, 4];
                                            case 3:
                                                e_1 = _a.sent();
                                                // 翻訳量が多いなどの理由で Lambda そのものがタイムアウトした場合などもここに入る
                                                console.error(e_1);
                                                alert('Failed to translate. 翻訳に失敗しました。');
                                                setLang(lang);
                                                return [2 /*return*/];
                                            case 4:
                                                url = new URL(window.location.href);
                                                url.searchParams.set('lang', selectedLang);
                                                window.history.replaceState(null, '', url.toString());
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }, { children: [_jsx("option", __assign({ css: styles.optionStyle, value: "ja" }, { children: "\u65E5\u672C\u8A9E" }), void 0),
                                    _jsx("option", __assign({ css: styles.optionStyle, value: "en" }, { children: "English" }), void 0),
                                    _jsx("option", __assign({ css: styles.optionStyle, value: "zh-cn" }, { children: "\u4E2D\u6587\uFF08\u7B80\u4F53\uFF09" }), void 0),
                                    _jsx("option", __assign({ css: styles.optionStyle, value: "zh-tw" }, { children: "\u4E2D\u6587\uFF08\u7E41\u9AD4\uFF09" }), void 0),
                                    _jsx("option", __assign({ css: styles.optionStyle, value: "ko" }, { children: "\uD55C\uAD6D\uC5B4" }), void 0)] }), void 0)] }), void 0),
                    _jsx("p", __assign({ css: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n              font-size: 12px;\n              color: #555;\n              text-align: right;\n              margin-right: 10px;\n            "], ["\n              font-size: 12px;\n              color: #555;\n              text-align: right;\n              margin-right: 10px;\n            "]))) }, { children: "Translation by AI" }), void 0)] }, void 0) }), void 0));
    };
    var mainContent = function () {
        var _a;
        if (!enquete || !enqueteSetting) {
            if (!isLoadingEnquete) {
                return (_jsxs("div", __assign({ css: styles.pageNotFoundWrapper }, { children: [_jsx("h1", __assign({ css: styles.pageNotFoundTitle }, { children: "Not Found" }), void 0),
                        _jsx("p", __assign({ css: styles.pageNotFoundDescription }, { children: msg.enqueteNotFound }), void 0)] }), void 0));
            }
            else {
                return _jsx(Loading, { primaryColor: "#777" }, void 0);
            }
        }
        var isRedirecting = enqueteSetting.redirectEnabled &&
            enqueteSetting.redirectUrl &&
            !isEditorPreview;
        if (progressNewEnqueteDesign || isLoadingEnquete || isRedirecting) {
            return _jsx(Loading, { primaryColor: "#777" }, void 0);
        }
        else if (visibleStartPageForIOSSafari) {
            return (_jsxs("div", __assign({ css: css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n            display: flex;\n            flex-direction: column;\n            min-height: calc(100vh - 20px);\n          "], ["\n            display: flex;\n            flex-direction: column;\n            min-height: calc(100vh - 20px);\n          "]))) }, { children: [(enqueteSetting === null || enqueteSetting === void 0 ? void 0 : enqueteSetting.autoTranslationEnabled) && languageSelector(),
                    _jsx(StartPageForIOSSafari, { themeColor: (_a = enqueteSetting.themeColor) !== null && _a !== void 0 ? _a : '#34b9bb', onStartEnquete: function () {
                            setVisibleStartPageForIOSSafari(false);
                            // #loaded が存在する状態で言語切替をしてしまうと、再度中間ページが開いてしまうため、ハッシュを消しておく
                            history.replaceState(null, document.title, window.location.pathname + window.location.search);
                        }, showChromeRecommend: enqueteSetting.showChromeRecommend }, void 0)] }), void 0));
        }
        else if (step === 1) {
            return (_jsxs(_Fragment, { children: [(enqueteSetting === null || enqueteSetting === void 0 ? void 0 : enqueteSetting.autoTranslationEnabled) && languageSelector(),
                    _jsx(FirstStep, { enquete: enquete, enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, workspace: workspace, isOpenedOnce: isFormOpenedOnce, setIsOpenedOnce: setIsFormOpenedOnce }, void 0)] }, void 0));
        }
        else if (step === 2) {
            return (_jsx(CheckGoogleAccount, { enqueteForm: enqueteForm, enqueteSetting: enqueteSetting, setEnqueteForm: setEnqueteForm }, void 0));
        }
        else if (step === 3) {
            return (_jsx(SecondStepGmb, { enquete: enquete, enqueteSetting: enqueteSetting, enqueteForm: enqueteForm }, void 0));
        }
        else {
            return null;
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(MessageContext.Provider, __assign({ value: { msg: msg } }, { children: [head(), showPreviewAlert && previewAlert(), mainContent()] }), void 0) }, void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
