var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import chroma from 'chroma-js';
export var darkThemeColor = function (enqueteSetting) {
    if (!enqueteSetting.themeColor) {
        return undefined;
    }
    return chroma(enqueteSetting.themeColor).darken(1).hex();
};
export var writeThemeColorStylesToHead = function (enqueteSetting) {
    var themeColor = enqueteSetting.themeColor;
    if (themeColor) {
        var dark = darkThemeColor(enqueteSetting);
        var style = document.createElement('style');
        style.textContent = "\n        h2.section-text {\n          color: #fff;\n          background-color: " + themeColor + ";\n        }\n        button {\n          background: " + themeColor + ";\n          border-bottom: solid 4px " + dark + ";\n        }\n        .cd-multi-steps.text-top li.visited > *::before,\n        .cd-multi-steps.text-top li.current > *::before, .cd-multi-steps.text-bottom li.visited > *::before,\n        .cd-multi-steps.text-bottom li.current > *::before {\n          background-color: " + dark + ";\n        }\n        .cd-multi-steps.text-top.count li:not(.current) em::before, .cd-multi-steps.text-bottom.count li:not(.current) em::before {\n            color: #777;\n        }\n        .cd-multi-steps li.visited::after {\n            background-color: " + themeColor + ";\n        }\n\n        ";
        document.head.appendChild(style);
    }
};
export var writeThemeColorStylesToHeadVer2 = function (enqueteSetting) {
    var themeColor = enqueteSetting.themeColor;
    if (themeColor) {
        var dark = darkThemeColor(enqueteSetting);
        var style = document.createElement('style');
        style.textContent = "\n\n        button {\n          background: " + themeColor + ";\n        }\n        .cd-multi-steps.text-top li.visited > *::before,\n        .cd-multi-steps.text-top li.current > *::before, .cd-multi-steps.text-bottom li.visited > *::before,\n        .cd-multi-steps.text-bottom li.current > *::before {\n          background-color: " + dark + ";\n        }\n        .cd-multi-steps.text-top.count li:not(.current) em::before, .cd-multi-steps.text-bottom.count li:not(.current) em::before {\n            color: #777;\n        }\n        .cd-multi-steps li.visited::after {\n            background-color: " + themeColor + ";\n        }\n\n        .enquete-ver2 .enquete-title, .enquete-ver2 .section-text, .enquete-ver2 .input-text__title{\n          color:" + themeColor + ";\n        }\n\n        .enquete-ver2 .name_section__input, .enquete-ver2 .input-text input[type='text'], .enquete-ver2 textarea{\n          border-color:" + themeColor + ";\n        }\n\n        .enquete-ver2 .choiceLabel.checked{\n          background-color: " + themeColor + ";\n          border-color:" + themeColor + ";\n        }\n\n        .after-enquete{\n          border-color:" + themeColor + ";\n        }\n\n        .enquete-ver2 .step1:before, .enquete-ver2 .step2:before{\n          color:" + themeColor + ";\n          border-color:" + themeColor + ";\n        }\n\n        .after-enquete,.enquete-ver2 .after-enquete--arrow-bg:before{\n          border-left-color:" + themeColor + ";\n        }\n\n        .enquete-ver2 .after-enquete--arrow-bg:after, .enquete-ver2 .google-step-info__title{\n          background-color:" + themeColor + ";\n        }\n\n        .enquete-ver2 .qr-mode-alert{\n          background-color: " + themeColor + ";\n        }\n\n        ";
        document.head.appendChild(style);
    }
};
export var replacePlaceholder = function (form, template) {
    if (!template) {
        return template;
    }
    var placeholders = Array.from(template.matchAll(/\${([a-zA-Z0-9]+)}/g));
    var _loop_1 = function (placeholder) {
        var fieldName = placeholder[1];
        var fieldValue = form.fieldValues.find(function (f) { return f.fieldUid === fieldName; });
        var value = __spreadArray(__spreadArray([], ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.values) || [])), [
            ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.other) && (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.otherValue)) || (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value),
        ]).filter(function (v) { return !!v; })
            .join(',');
        // まだ対象フィールドが未設定の場合、undedinedを返してデフォルト値を表示させる。
        if (!value) {
            return { value: undefined };
        }
        template = template === null || template === void 0 ? void 0 : template.replace(placeholder[0], value);
    };
    for (var _i = 0, placeholders_1 = placeholders; _i < placeholders_1.length; _i++) {
        var placeholder = placeholders_1[_i];
        var state_1 = _loop_1(placeholder);
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return template;
};
