import React, { useContext } from 'react';
import { MessageContext } from '../../../context/message-context';
import GoogleStep1 from './img/google_step1.svg';
import GoogleStep2 from './img/google_step2.svg';
import GoogleStep3 from './img/google_step3.svg';
import GoogleStep4 from './img/google_step4.svg';
import GoogleStep5 from './img/google_step5.svg';
import GoogleStep6 from './img/google_step6.svg';
import PhoneTouchIcon from './img/phone_touch_icon.png';
import PhoneTouchWithPersonIcon from './img/phone_touch_with_person_icon.svg';
export default function GoogleStep() {
    var msg = useContext(MessageContext).msg;
    var gooleSteps = [
        {
            text: msg.googleStep.step1,
            image: GoogleStep1,
        },
        {
            text: msg.googleStep.step2,
            image: GoogleStep2,
        },
        {
            text: msg.googleStep.step3,
            image: GoogleStep3,
        },
        {
            text: msg.googleStep.step4,
            image: GoogleStep4,
        },
        {
            text: msg.googleStep.step5,
            image: GoogleStep5,
        },
        {
            text: msg.googleStep.step6,
            image: GoogleStep6,
        },
    ];
    var gooleStepInfo = function (text, contentImg, index) {
        return (React.createElement("div", { className: "google-step-info", key: 'step' + index },
            React.createElement("h1", { className: "google-step-info__title" }, text),
            React.createElement("div", { className: "google-step-info__img" },
                React.createElement("img", { src: contentImg, alt: "" }))));
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "step-support" },
            React.createElement("div", { className: "after-enquete--arrow-bg" }),
            React.createElement("div", { className: "step-support--text" },
                React.createElement("p", null, msg.googleStep.googleAccountPageNextStep)),
            React.createElement("div", { className: "step-support--icon" },
                React.createElement("img", { src: PhoneTouchIcon, alt: "" }))),
        React.createElement("div", null, gooleSteps.map(function (data, index) {
            return gooleStepInfo(data.text, data.image, index);
        })),
        React.createElement("div", { className: "step-support" },
            React.createElement("div", { className: "step-support--text", style: { width: 'auto' } },
                React.createElement("p", { style: { textAlign: 'center' } }, msg.googleStep.goToGooglePageNow)),
            React.createElement("div", { className: "step-support--icon" },
                React.createElement("img", { src: PhoneTouchWithPersonIcon, alt: "" })))));
}
