import axios from 'axios';
import { useEffect } from 'react';
import { API_END_POINT } from './api';
var useEnquetePageviewHistoriesAdd = function (enqueteId, noAdd) {
    var searchParams = new URL(window.location.href).searchParams;
    var modeParam = searchParams.get('mode');
    var inflowSourceParam = searchParams.get('_src');
    useEffect(function () {
        if (!enqueteId ||
            modeParam === 'preview' ||
            inflowSourceParam === 'プレビュー' ||
            noAdd) {
            return;
        }
        var data = {
            inflowSource: inflowSourceParam,
        };
        axios
            .post(API_END_POINT + "/public/enquetes/" + enqueteId + "/page-view-histories", data)
            .catch(function (e) { return console.error(e); });
    }, []);
};
export { useEnquetePageviewHistoriesAdd };
