import axios from 'axios';
import { useState } from 'react';
import { API_END_POINT } from './api';
export default function useEnquete(enqueteId) {
    var _a = useState(), enquete = _a[0], setEnquete = _a[1];
    var _b = useState(), workspace = _b[0], setWorkspace = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var reload = function (lang) {
        setLoading(true);
        if (!enqueteId) {
            setLoading(false);
            return;
        }
        return axios
            .get(API_END_POINT + "/public/enquetes/" + enqueteId, {
            params: { lang: lang },
        })
            .then(function (response) {
            setWorkspace(response.data.workspace);
            setEnquete(response.data.enquete);
            return response.data;
        })
            .finally(function () {
            setLoading(false);
        });
    };
    var updateEnquete = function (newValue) {
        setEnquete(newValue);
    };
    return {
        enquete: enquete,
        reload: reload,
        updateEnquete: updateEnquete,
        workspace: workspace,
        isLoadingEnquete: loading,
    };
}
