/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
export var messages = {
    checkGoogleAccount: {
        existsAccount: React.createElement(React.Fragment, null, "\u60A8\u6709Google\u5E33\u6236\uFF08Gmail\u5730\u5740\uFF09\u55CE\uFF1F"),
        yes: '有',
        no: '沒有',
    },
    commentEnterFeedback: '請協助填寫您的意見和感想。',
    commentEnterFreeForm: '自由填寫',
    commentEnterInputGoodPoint: '請填寫您推薦給朋友或滿意的地方。',
    enquete: '顧客滿意度調查',
    enqueteNotFound: '問卷不存在或無法加載。',
    googleStep: {
        googleAccountPageNextStep: React.createElement(React.Fragment, null, "\u9019\u662F\u9032\u5165Google\u5E33\u6236\u9801\u9762\u5F8C\u7684\u6B65\u9A5F\u3002"),
        goToGooglePageNow: React.createElement(React.Fragment, null, "\u7ACB\u5373\u524D\u5F80Google\u9801\u9762\uFF01"),
        step1: '點擊“分享您在此地的體驗或感想”部分',
        step2: '長按輸入框，顯示“貼上”時點擊',
        step3: '文本貼上後點擊“完成”',
        step4: '點擊★（星）',
        step5: '如果有選項，請選擇最接近您感想的標籤並點擊',
        step6: '點擊螢幕右上角的“發佈”即可完成',
    },
    loading: '加載中...',
    multipleSelectAllowed: '可多選',
    name: '姓名',
    namePlaceholder: '例) 田中 一郎',
    next: '下一步',
    npsOption1: '非常不願意推薦',
    npsOption2: '不願意推薦',
    npsOption3: '願意推薦',
    npsOption4: '非常願意推薦',
    npsText: '您願意向朋友推薦我們的店嗎？',
    other: '其他',
    otherPlaceholder: '請填寫其他內容',
    plaeseAgree: '同意隱私政策',
    pleaseAgreeWithUrl: function (privacyPolicyUrl) { return (React.createElement(React.Fragment, null,
        React.createElement("a", { className: "privacy-policy-text", href: privacyPolicyUrl, target: "_blank", rel: "noreferrer", style: { textDecoration: 'underline' } }, "\u96B1\u79C1\u653F\u7B56"),
        "\u540C\u610F")); },
    pleaseEnter: '請輸入。',
    pleaseEnterTextLength: function (min, length) {
        return "\u8ACB\u81F3\u5C11\u8F38\u5165" + min + "\u500B\u5B57\u5143: \u76EE\u524D" + length + "\u500B\u5B57\u5143";
    },
    pleaseSelect: '請選擇。',
    pleaseSelectOneOrMore: '請至少選擇一個。',
    alertForIOS: {
        qrReaderPattern: {
            title: (React.createElement(React.Fragment, null,
                "\u5982\u679C\u87A2\u5E55\u53F3\u4E0B\u89D2\u6709\u5982\u5716",
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\u5716\u793A",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\u5B58\u5728")),
                "\u7684\u60C5\u6CC1")),
            description: '請點擊螢幕右下角的圖示，並在Safari中填寫問卷。',
            caption: 'Safari圖示範例',
            directOpenLink: function (onStartEnquete) { return (React.createElement(React.Fragment, null,
                "\u5982\u679C\u7121\u6CD5\u901A\u904E\u4E0A\u8FF0\u6309\u9215\u958B\u555F\uFF0C\u8ACB",
                React.createElement("a", { style: { color: 'blue', textDecoration: 'underline' }, onClick: onStartEnquete }, "\u9EDE\u64CA\u9019\u88E1"),
                "\u3002")); },
        },
        safariPattern: {
            title: (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\u5716\u793A",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\u4E0D\u5B58\u5728")),
                "\u7684\u60C5\u6CC1")),
            description: '請點擊以下按鈕。',
            enqueteStartButton: '開始問卷調查',
        },
    },
    required: '必填',
    secondStep: {
        followOfficialSnsForDeals: React.createElement(React.Fragment, null, "\u5B98\u65B9SNS\u4E5F\u767C\u5E03\u512A\u60E0\u4FE1\u606F\uFF0C\u8ACB\u95DC\u6CE8\u3002"),
        googleReviewRequestMessage: (React.createElement(React.Fragment, null, "\u5982\u679C\u53EF\u4EE5\uFF0C\u8ACB\u5354\u52A9\u5728Google\u8A55\u8AD6\u4E2D\u767C\u8868\u60A8\u7684\u770B\u6CD5\uFF01")),
        responseDateTime: '回答日期時間',
        responseId: '回答ID',
        surveyThanksMessage: React.createElement(React.Fragment, null, "\u611F\u8B1D\u60A8\u7684\u554F\u5377\u8ABF\u67E5\u3002"),
        useForFutureOperation: '我們將用於未來的運營。',
    },
    toGoogle: {
        copyToGoogle: '複製到Google',
        freeCommentCopyOnButton: '按下按鈕，“自由評論欄”的內容將被複製',
        missingPlaceIdOrShortName: '未設定簡稱或Place ID。',
        moveToGoogleAndPasteReview: '按下按鈕將跳轉至Google帳戶頁面。在評論欄貼上內容並使用。',
    },
    textValidationError: {
        number: '請輸入數字',
        alphanumeric: '請輸入半形英數字',
        email: '請輸入正確的電子郵件格式',
    },
};
