import React, { useContext } from 'react';
import { MessageContext } from '../../context/message-context';
export var TextFieldErrorMessage = function (props) {
    var required = props.required, field = props.field, fieldValue = props.fieldValue;
    var msg = useContext(MessageContext).msg;
    if (required && !(fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value)) {
        return (React.createElement("div", { className: "error-text" },
            React.createElement("p", null, msg.pleaseEnter)));
    }
    else if (field.textValidationType === 'number') {
        return (React.createElement("div", { className: "error-text" },
            React.createElement("p", null, msg.textValidationError.number)));
    }
    else if (field.textValidationType === 'alphanumeric') {
        return (React.createElement("div", { className: "error-text" },
            React.createElement("p", null, msg.textValidationError.alphanumeric)));
    }
    else if (field.textValidationType === 'email') {
        return (React.createElement("div", { className: "error-text" },
            React.createElement("p", null, msg.textValidationError.email)));
    }
    return null;
};
