import { useEffect, useState } from 'react';
import { writeThemeColorStylesToHeadVer2 } from '../utils/EnqueteHelper';
var useEnqueteDesign2 = function (enquete) {
    var _a = useState(true), progressNewEnqueteDesign = _a[0], setProgressNewEnqueteDesign = _a[1];
    useEffect(function () {
        if (!enquete) {
            return;
        }
        setProgressNewEnqueteDesign(true);
        document.getElementsByTagName('body')[0].classList.add('enquete-ver2');
        writeThemeColorStylesToHeadVer2(JSON.parse(enquete.json));
        setProgressNewEnqueteDesign(false);
    }, [enquete]);
    return { progressNewEnqueteDesign: progressNewEnqueteDesign };
};
export default useEnqueteDesign2;
