import React from 'react';
import { testStart } from '../utils/GoogleAnalyticsUtil';
import GoogleStep from './SecondStepGmb/GoogleStep/index';
import PromotionBanner from './SecondStepGmb/PromotionLink';
import SecondStepTitle from './SecondStepGmb/SecondStepTitle/index';
import SnsFollow from './SecondStepGmb/SnsFollow/index';
import ToGoogle from './SecondStepGmb/ToGoogle/index';
export default function SecondStepGmb(props) {
    var enquete = props.enquete, enqueteSetting = props.enqueteSetting, enqueteForm = props.enqueteForm;
    var hasGoogleAccount = function () {
        return enqueteForm.hasGoogleAccount;
    };
    var getAllSnsLinks = function () {
        if (!enqueteSetting.snsLink) {
            return [];
        }
        return [
            enqueteSetting.snsLink.facebookLink,
            enqueteSetting.snsLink.instagramLink,
            enqueteSetting.snsLink.lineLink,
            enqueteSetting.snsLink.twitterLink,
            enqueteSetting.snsLink.youtubeLink,
            enqueteSetting.snsLink.tiktokLink,
            enqueteSetting.snsLink.pinterestLink,
        ];
    };
    var isSettingSnsLink = function () {
        return (enqueteSetting.snsLink !== undefined &&
            getAllSnsLinks().some(function (link) { return link !== ''; }));
    };
    var isSettingPromotionLink = function () {
        return Boolean(enqueteSetting.promotionLink !== undefined &&
            enqueteSetting.promotionLink.bannerImage);
    };
    var mainContent = function () {
        if (hasGoogleAccount()) {
            testStart();
            return (React.createElement("div", { className: "enquete-step2" },
                React.createElement("div", null,
                    React.createElement(SecondStepTitle, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, titleType: 'google' })),
                React.createElement("div", null,
                    React.createElement("div", { className: "toGoogle--page-upper" },
                        React.createElement(ToGoogle, { enquete: enquete, enqueteForm: enqueteForm, eventLabel: "pageUpper" })),
                    React.createElement("div", null,
                        React.createElement(GoogleStep, null)),
                    React.createElement("div", { style: { marginTop: '-4px' }, className: "toGoogle--page-lower" },
                        React.createElement(ToGoogle, { enquete: enquete, enqueteForm: enqueteForm, eventLabel: "pageLower" })))));
        }
        else {
            return (React.createElement("div", { className: "enquete-step2" },
                React.createElement("div", null,
                    React.createElement(SecondStepTitle, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, titleType: isSettingSnsLink() ? 'snsLink' : 'thanks', hideTitleImage: isSettingPromotionLink() })),
                isSettingSnsLink() && (React.createElement("div", null,
                    React.createElement(SnsFollow, { enquete: enquete, enqueteForm: enqueteForm, enqueteSetting: enqueteSetting }))),
                isSettingPromotionLink() && (React.createElement("div", null,
                    React.createElement(PromotionBanner, { enqueteSetting: enqueteSetting })))));
        }
    };
    return React.createElement("div", null, mainContent());
}
