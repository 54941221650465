import React from 'react';
import { lineBreakToParagraphAndHtml } from '../../../utils/browserUtils';
export default function PromotionBanner(props) {
    var _a, _b, _c, _d, _e;
    var enqueteSetting = props.enqueteSetting;
    return (React.createElement("div", null,
        React.createElement("div", { style: {
                display: 'flex',
                flexWrap: 'wrap',
                fontSize: '16px',
                marginTop: 20,
            } },
            ((_a = enqueteSetting.promotionLink) === null || _a === void 0 ? void 0 : _a.message) && (React.createElement("div", null, lineBreakToParagraphAndHtml(enqueteSetting.promotionLink.message))),
            ((_b = enqueteSetting.promotionLink) === null || _b === void 0 ? void 0 : _b.url) ? (React.createElement("a", { href: (_c = enqueteSetting.promotionLink) === null || _c === void 0 ? void 0 : _c.url, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { src: (_d = enqueteSetting.promotionLink) === null || _d === void 0 ? void 0 : _d.bannerImage, alt: "\u30D7\u30ED\u30E2\u30FC\u30B7\u30E7\u30F3\u30D0\u30CA\u30FC", style: { width: '100%', marginBottom: 16 } }))) : (React.createElement("img", { src: (_e = enqueteSetting.promotionLink) === null || _e === void 0 ? void 0 : _e.bannerImage, alt: "\u30D7\u30ED\u30E2\u30FC\u30B7\u30E7\u30F3\u30D0\u30CA\u30FC", style: { width: '100%', marginBottom: 16 } })))));
}
