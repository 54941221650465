import axios from 'axios';
import React, { useContext } from 'react';
import { API_END_POINT } from '../../../api/api';
import { MessageContext } from '../../../context/message-context';
import { sendData } from '../../../utils/GoogleAnalyticsUtil';
import { copyToClipborad } from '../../../utils/browserUtils';
export default function ToGoogle(props) {
    var enquete = props.enquete, enqueteForm = props.enqueteForm, eventLabel = props.eventLabel;
    var msg = useContext(MessageContext).msg;
    var json = JSON.parse(enquete.json);
    var goGmbReviewPageWithSafari = function () {
        if (enquete.gmbReviewShortUrl) {
            window.location.href = enquete.gmbReviewShortUrl;
        }
        else if (enquete.gmbShortName) {
            window.location.href = "https://g.page/" + encodeURIComponent(enquete.gmbShortName) + "/review?iv";
        }
        else if (enquete.gmbPlaceId) {
            window.location.href = "https://search.google.com/local/writereview?placeid=" + encodeURIComponent(enquete.gmbPlaceId);
        }
        else {
            alert(msg.toGoogle.missingPlaceIdOrShortName);
        }
    };
    var showGmbReviewPageWithSafari = function () {
        if (enqueteForm.responseId) {
            axios
                .post(API_END_POINT + "/public/enquetes/" + enquete.uid + "/responses/" + enqueteForm.responseId + "/tracking", { value: 'go_review_clicked' })
                .then(function () {
                // nothing
            })
                .finally(function () {
                goGmbReviewPageWithSafari();
            });
        }
        else {
            goGmbReviewPageWithSafari();
        }
    };
    var handleCopyReview = function () {
        var comment = enqueteForm.comment;
        copyToClipborad(comment);
        showGmbReviewPageWithSafari();
    };
    var handleClickGoReview = function (eventLabel) {
        sendData('click「to Google」', 'ページ「アンケート完了」', eventLabel);
        handleCopyReview();
    };
    return (React.createElement("div", { className: "after-enquete" },
        React.createElement("p", null, msg.toGoogle.freeCommentCopyOnButton),
        React.createElement("button", { onClick: function () {
                handleClickGoReview(eventLabel);
            }, className: "google-link-button" }, json.copyToGoogleButtonText
            ? json.copyToGoogleButtonText
            : msg.toGoogle.copyToGoogle),
        React.createElement("p", { className: "attention-msg" }, msg.toGoogle.moveToGoogleAndPasteReview)));
}
