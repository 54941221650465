import { supportedEnqueteLangs } from '../@interfaces/enquete';
import { messages as en } from './en';
import { messages as ja } from './ja';
import { messages as ko } from './ko';
import { messages as zhCn } from './zh-cn';
import { messages as zhTw } from './zh-tw';
var Messages = /** @class */ (function () {
    function Messages() {
    }
    Messages.get = function (targetLang) {
        var langs = targetLang
            ? [targetLang]
            : window.navigator.languages.map(function (lang) { return lang.split('-')[0]; });
        var lang = langs.find(Messages.isSupportLang) || 'ja';
        return Messages.of(lang);
    };
    Messages.isSupportLang = function (lang) {
        return lang !== undefined && supportedEnqueteLangs.includes(lang);
    };
    Messages.of = function (language) {
        if (language === undefined) {
            return this.map.en;
        }
        return this.map[language];
    };
    Messages.map = {
        en: en,
        ja: ja,
        'zh-cn': zhCn,
        'zh-tw': zhTw,
        ko: ko,
    };
    return Messages;
}());
export default Messages;
