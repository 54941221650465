/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
export var messages = {
    checkGoogleAccount: {
        existsAccount: React.createElement(React.Fragment, null, "\u60A8\u6709Google\u5E10\u6237\uFF08Gmail\u5730\u5740\uFF09\u5417\uFF1F"),
        yes: '有',
        no: '没有',
    },
    commentEnterFeedback: '请协助填写您的意见和反馈。',
    commentEnterFreeForm: '自由填写',
    commentEnterInputGoodPoint: '请填写您推荐给朋友或满意的地方。',
    enquete: '客户满意度调查',
    enqueteNotFound: '问卷不存在或无法加载。',
    googleStep: {
        googleAccountPageNextStep: React.createElement(React.Fragment, null, "\u8FD9\u662F\u8FDB\u5165Google\u5E10\u6237\u9875\u9762\u540E\u7684\u6B65\u9AA4\u3002"),
        goToGooglePageNow: React.createElement(React.Fragment, null, "\u7ACB\u5373\u524D\u5F80Google\u9875\u9762\uFF01"),
        step1: '点击“分享您在此地的体验或感想”部分',
        step2: '长按输入框，显示“粘贴”时点击',
        step3: '文本粘贴后点击“完成”',
        step4: '点击★（星）',
        step5: '如果有选项，请选择最接近您感想的标签并点击',
        step6: '点击屏幕右上角的“发布”即可完成',
    },
    loading: '加载中...',
    multipleSelectAllowed: '可多选',
    name: '姓名',
    namePlaceholder: '例) 田中 一郎',
    next: '下一步',
    npsOption1: '非常不愿意推荐',
    npsOption2: '不愿意推荐',
    npsOption3: '愿意推荐',
    npsOption4: '非常愿意推荐',
    npsText: '您愿意向朋友推荐我们的店吗？',
    other: '其他',
    otherPlaceholder: '请填写其他内容',
    plaeseAgree: '同意隐私政策',
    pleaseAgreeWithUrl: function (privacyPolicyUrl) { return (React.createElement(React.Fragment, null,
        React.createElement("a", { className: "privacy-policy-text", href: privacyPolicyUrl, target: "_blank", rel: "noreferrer", style: { textDecoration: 'underline' } }, "\u9690\u79C1\u653F\u7B56"),
        "\u540C\u610F")); },
    pleaseEnter: '请输入。',
    pleaseEnterTextLength: function (min, length) {
        return "\u8BF7\u81F3\u5C11\u8F93\u5165" + min + "\u4E2A\u5B57\u7B26: \u5F53\u524D" + length + "\u4E2A\u5B57\u7B26";
    },
    pleaseSelect: '请选择。',
    pleaseSelectOneOrMore: '请至少选择一个。',
    alertForIOS: {
        qrReaderPattern: {
            title: (React.createElement(React.Fragment, null,
                "\u5982\u679C\u5C4F\u5E55\u53F3\u4E0B\u89D2\u6709\u5982\u56FE",
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\u56FE\u6807",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\u5B58\u5728")),
                "\u7684\u60C5\u51B5")),
            description: '请点击屏幕右下角的图标，并在Safari中填写问卷。',
            caption: 'Safari图标示例',
            directOpenLink: function (onStartEnquete) { return (React.createElement(React.Fragment, null,
                "\u5982\u679C\u65E0\u6CD5\u901A\u8FC7\u4E0A\u8FF0\u6309\u94AE\u6253\u5F00\uFF0C\u8BF7",
                React.createElement("a", { style: { color: 'blue', textDecoration: 'underline' }, onClick: onStartEnquete }, "\u70B9\u51FB\u8FD9\u91CC"),
                "\u3002")); },
        },
        safariPattern: {
            title: (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\u56FE\u6807",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\u4E0D\u5B58\u5728")),
                "\u7684\u60C5\u51B5")),
            description: '请点击以下按钮。',
            enqueteStartButton: '开始问卷调查',
        },
    },
    required: '必填',
    secondStep: {
        followOfficialSnsForDeals: React.createElement(React.Fragment, null, "\u5B98\u65B9SNS\u4E5F\u53D1\u5E03\u4F18\u60E0\u4FE1\u606F\uFF0C\u8BF7\u5173\u6CE8\u3002"),
        googleReviewRequestMessage: (React.createElement(React.Fragment, null, "\u5982\u679C\u53EF\u4EE5\uFF0C\u8BF7\u534F\u52A9\u5728Google\u8BC4\u8BBA\u4E2D\u53D1\u8868\u60A8\u7684\u770B\u6CD5\uFF01")),
        responseDateTime: '回答日期时间',
        responseId: '回答ID',
        surveyThanksMessage: React.createElement(React.Fragment, null, "\u611F\u8C22\u60A8\u7684\u95EE\u5377\u8C03\u67E5\u3002"),
        useForFutureOperation: '我们将用于未来的运营。',
    },
    toGoogle: {
        copyToGoogle: '复制到Google',
        freeCommentCopyOnButton: '按下按钮，“自由评论栏”的内容将被复制',
        missingPlaceIdOrShortName: '未设置简称或Place ID。',
        moveToGoogleAndPasteReview: '按下按钮将跳转至Google帐户页面。在评论栏粘贴内容并使用。',
    },
    textValidationError: {
        number: '请输入数字',
        alphanumeric: '请输入半角英数字',
        email: '请输入正确的电子邮件格式',
    },
};
