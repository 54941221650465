import React, { useContext } from 'react';
import ReactLoading from 'react-loading';
import { MessageContext } from '../context/message-context';
export function Loading(props) {
    var primaryColor = props.primaryColor;
    var msg = useContext(MessageContext).msg;
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(ReactLoading, { className: "page-loading", type: "spokes", color: primaryColor, height: '20px', width: '20px' }),
        msg.loading));
}
