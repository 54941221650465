export var supportedEnqueteLangs = [
    'en',
    'ja',
    'zh-cn',
    'zh-tw',
    'ko',
];
export var textValidationTypeLabels = {
    number: '数字のみ',
    alphanumeric: '半角英数字のみ',
    email: 'メールアドレス形式のみ',
};
export var NpsValueLabels = {
    1: 'とても紹介したくない',
    2: '紹介したくない',
    3: '紹介したい',
    4: 'とても紹介したい',
};
export var DEFAULT_NPS_TEXT = '当店をお知り合いなどにおすすめしたいと思いますか？';
